import React from 'react';
import '../styles/FAQ.css';

const FAQ = () => {
  const faqItems = [
    {
      question: 'O salão funciona realmente 24 horas?',
      answer: 'Sim, estamos abertos 24 horas por dia para garantir o máximo de conveniência aos nossos clientes.'
    },
    {
      question: 'Como faço para agendar um serviço?',
      answer: 'Você pode agendar online, pelo WhatsApp ou diretamente por telefone.'
    },
    {
      question: 'Quais formas de pagamento vocês aceitam?',
      answer: 'Aceitamos pagamentos em cartão de crédito, débito e transferências bancárias.'
    },
    {
      question: 'Posso contratar pacotes personalizados?',
      answer: 'Claro! Temos pacotes que podem ser personalizados para atender suas necessidades específicas.'
    }
  ];

  return (
    <div className="faq-section">
      <h2>Perguntas Frequentes</h2>
      <div className="faq-items">
        {faqItems.map((item, index) => (
          <div key={index} className="faq-item">
            <h3>{item.question}</h3>
            <p>{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
