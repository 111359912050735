import React from 'react';
import '../styles/ServicePackages.css';

const ServicePackages = () => {
  return (
    <div className="service-packages">
      <h2>Pacotes e Assinaturas</h2>
      <table className="packages-table">
        <thead>
          <tr>
            <th>Pacote</th>
            <th>Serviços Incluídos</th>
            <th>Preço</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bronze</td>
            <td>1 serviço limitado (cabelo, manicure ou estética)</td>
            <td>R$ 300,00</td>
          </tr>
          <tr>
            <td>Silver</td>
            <td>2 serviços ilimitados (cabelo e manicure, ou estética e cabelo)</td>
            <td>R$ 500,00</td>
          </tr>
          <tr>
            <td>Gold</td>
            <td>2 serviços ilimitados por 6 meses</td>
            <td>R$ 800,00</td>
          </tr>
          <tr>
            <td>Platinum</td>
            <td>Todos os serviços inclusos por 9 meses</td>
            <td>R$ 1.000,00</td>
          </tr>
          <tr>
            <td>Diamond</td>
            <td>Todos os serviços inclusos por 12 meses</td>
            <td>R$ 1.500,00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ServicePackages;