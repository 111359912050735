import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import Logo from './Logo';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <div className="logo-container">
        <Logo />
      </div>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
        <li><Link to="/services" onClick={toggleMenu}>Serviços</Link></li>
        <li><Link to="/experience" onClick={toggleMenu}>Experiência</Link></li>
        <li><Link to="/team" onClick={toggleMenu}>Equipe</Link></li>
        <li><Link to="/contact" onClick={toggleMenu}>Contato</Link></li>
        <li><Link to="/blog" onClick={toggleMenu}>Blog</Link></li>
        <li><Link to="/faq" onClick={toggleMenu}>FAQ</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
