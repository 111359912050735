import React from 'react';
import '../styles/Blog.css';

const Blog = () => {
  const blogPosts = [
    {
      title: 'Tendências de Beleza em 2024',
      summary: 'Descubra as tendências de beleza que estão em alta neste ano...',
      link: '/blog/tendencias-beleza-2024'
    },
    {
      title: 'Cuidados Essenciais com a Pele no Inverno',
      summary: 'O inverno está chegando e é essencial cuidar bem da pele para mantê-la saudável...',
      link: '/blog/cuidados-pele-inverno'
    },
    {
      title: 'Como Escolher o Melhor Corte de Cabelo para Você',
      summary: 'Saiba como escolher o corte de cabelo ideal de acordo com o seu rosto e estilo...',
      link: '/blog/melhor-corte-cabelo'
    }
  ];

  return (
    <div className="blog-section">
      <h2>Nosso Blog</h2>
      <div className="blog-posts">
        {blogPosts.map((post, index) => (
          <div key={index} className="blog-post">
            <h3>{post.title}</h3>
            <p>{post.summary}</p>
            <a href={post.link}>Leia mais</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
