import React from 'react';
import '../styles/Team.css';

const Team = () => {
  const teamMembers = [
    {
      name: 'Juliana Santos',
      role: 'Cabeleireira Master',
      img: 'https://i.imgur.com/k1saxMi.jpg',
      description: 'Especialista em coloração e penteados para ocasiões especiais.'
    },
    {
      name: 'Marcos Oliveira',
      role: 'Esteticista',
      img: 'https://i.imgur.com/Fva5nT7.jpg',
      description: 'Expert em cuidados com a pele e tratamentos faciais.'
    },
    {
      name: 'Ana Costa',
      role: 'Manicure e Pedicure',
      img: 'https://i.imgur.com/As1LpaO.jpg',
      description: 'Conhecida por suas técnicas únicas de nail art e tratamentos de spa para pés e mãos.'
    },
    {
      name: 'Camila Alvarez',
      role: 'Direção e Sócia',
      img: 'https://i.imgur.com/tWTlUKw.jpg',
      description: 'Sócia e responsável pela gestão estratégica do Enjoy Style Center.'
    }
  ];

  return (
    <div className="team-section">
      <h2>Conheça Nossa Equipe</h2>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.img} alt={member.name} />
            <h3>{member.name}</h3>
            <p><strong>{member.role}</strong></p>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
