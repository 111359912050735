import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <div className="about-section">
      <h2>Sobre o Enjoy Style Center</h2>
      <p>
        O Enjoy Style Center oferece uma experiência premium de beleza 24 horas por dia, proporcionando luxo e conforto em cada detalhe. Nosso objetivo é oferecer um autocuidado de alto padrão, adaptado a cada necessidade.
      </p>
    </div>
  );
};

export default About;
