import React from 'react';
import ExperienceGallery from '../components/ExperienceGallery';

const ExperiencePage = () => {
  return (
    <div>
      <ExperienceGallery />
    </div>
  );
};

export default ExperiencePage;