import React from 'react';
import Team from '../components/Team';

const TeamPage = () => {
  return (
    <div>
      <Team />
    </div>
  );
};

export default TeamPage;
