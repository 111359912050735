import React from 'react';
import About from '../components/About';
import ImageCarousel from '../components/Carousel';

const HomePage = () => {
  return (
    <div>
      <About />
      <ImageCarousel />
    </div>
  );
};

export default HomePage;