import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Carousel.css';

const ImageCarousel = () => {
  return (
    <div className="carousel-container">
      <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
        <div>
          <img src="https://i.imgur.com/u3AnyRZ.jpeg" alt="Imagem 1" />
        </div>
        <div>
          <img src="https://i.imgur.com/yceAXGp.jpeg" alt="Imagem 2" />
        </div>
        <div>
          <img src="https://i.imgur.com/b94VCwN.png" alt="Imagem 3" />
        </div>
        <div>
          <img src="https://i.imgur.com/6gDIVEY.jpeg" alt="Imagem 4" />
        </div>
        <div>
          <img src="https://i.imgur.com/fZewtAh.jpeg" alt="Imagem 5" />
        </div>
        <div>
          <img src="https://i.imgur.com/bID7hrj.jpeg" alt="Imagem 6" />
        </div>
        <div>
          <img src="https://i.imgur.com/7BvUI36.jpeg" alt="Imagem 7" />
        </div>
        <div>
          <img src="https://i.imgur.com/9Vz0msr.jpeg" alt="Fachada" />
        </div>
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
