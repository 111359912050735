import React from 'react';
import '../styles/ExperienceGallery.css';

const ExperienceGallery = () => {
  const images = [
    "https://i.imgur.com/fZewtAh.jpeg",
    "https://i.imgur.com/6gDIVEY.jpeg",
    "https://i.imgur.com/b94VCwN.png",
    "https://i.imgur.com/yceAXGp.jpeg"
  ];

  return (
    <div className="experience-gallery">
      <h2>Nosso Espaço</h2>
      <div className="gallery-grid">
        {images.map((img, index) => (
          <div key={index} className="gallery-item">
            <img src={img} alt={`Ambiente ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperienceGallery;
