import React from 'react';
import '../styles/ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contact-form">
      <h2>Entre em Contato</h2>
      <form>
        <label htmlFor="name">Nome</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">E-mail</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="message">Mensagem</label>
        <textarea id="message" name="message" rows="5" required></textarea>

        <button type="submit">Enviar Mensagem</button>
      </form>
    </div>
  );
};

export default ContactForm;
