import React from 'react';

const Logo = () => {
  return (
    <div className="logo-container">
      <img 
        src="https://i.imgur.com/evmmZZY.png" 
        alt="Logo Enjoy Style Center" 
        className="site-logo" 
        style={{ maxWidth: '150px', height: 'auto' }} 
      />
    </div>
  );
};

export default Logo;
